<template>
    <div class="contact-details-main">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="container-fluid">
            <Header v-if="showHeader" />
            <div class="main-body-wrapper">
                <div class="container-fluid">
                    <div class="contact-details-top">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item"><a href="#">Core</a></li>
                                <li class="breadcrumb-item">
                                    <router-link to="/brands-listing" replace>Brands</router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">{{brand_name}}</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="heading">
                         <router-link  to="/brands-default" replace>
                         <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M15 8H1" stroke="#0F123F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 15L1 8L8 1" stroke="#0F123F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                         
                         
                         
                         </router-link>
                        <p>Edit Item </p>
                    </div>
                    <div class="add-new">
                        <div class="container-fluid">
                            <div class="add-details-heading">
                                <p>ITEM DETAILS</p>
                            </div>
                            <div class="add-detail-switch">
                                <div class="switch-2">
                                    Available
                                    <label class="switch-inner1">
                                        <input type="checkbox" id="togBtn22" v-model="available">
                                        <div  :class="['slider ', available === true ? 'round' : 'round disabled']">
                                        </div>
                                    </label>
                                </div>
                                <div class="switch-2">
                                    Active
                                    <label class="switch-inner1">
                                        <input type="checkbox" id="togBtn23"  v-model="active">
                                        <div  :class="['slider ', active === true ? 'round' : 'round disabled']">
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="border-bottom">
                        </div>
                        <div class="address-details">
                            <div class="container-fluid">
                                <div class="form-2">
                                    <div class="form-group">
                                        <label for="formGroupExampleInput9">Item Name<span class="star">*</span></label>
                                        <input type="text" class="form-control" id="formGroupExampleInput9" placeholder="..." required v-model="item_name">
                                        <small id="emailHelp" class="form-text text-muted">This is the name that your customers will see on Tabletop. Make sure you check your spelling, including capitalisations and spaces.</small>
                                        <span v-if="item_name_error" class="error_alert">{{item_name_error_message}}</span>
                                    </div>
                                    <div class="form-group">
                                        <div class="area-drop-1">
                                            <label>Menu Category</label>
                                            <div class="dropdown">
                                                <v-select label="name" :options="categories"  v-model="menu_category" ></v-select>
                                                <span v-if="menu_category_error" class="error_alert">{{menu_category_error_message}}</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="check-limit">
                                        <label class="container">
                                        <input type="checkbox" checked="checked"  v-model="signature_dash">
                                        <span class="checkmark"></span>
                                        Signature Dish
                                        </label>
                                        <label class="container">
                                        <input type="checkbox" checked="checked" v-model="discount">
                                        <span class="checkmark"></span>
                                        Discount
                                        </label>
                                        <label class="container">
                                        <input type="checkbox" checked="checked"  v-model="promotion">
                                        <span class="checkmark"></span>
                                        Promotion 
                                        </label>
                                    </div>
                                    <div class="form-group">
                                        <label for="formGroupExampleInput10">Price</label>
                                        <input type="number" class="form-control" id="formGroupExampleInput10" placeholder="$" required  v-model="price">
                                        <span v-if="price_error" class="error_alert">{{price_error_message}}</span>
                                    </div>
                                    <div class="sales-channels">
                                        <label>Service Type</label>
                                        <div class="sales-channel-inner">
                                            <div class="switch-2">
                                                Dine-in
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn20" v-model="dine_in">
                                                    <div :class="['slider ', dine_in === true ? 'round' : 'round disabled']">
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="switch-2">
                                                Take-Away
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn21" v-model="take_away">
                                                    <div :class="['slider ', take_away === true ? 'round' : 'round disabled']">
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="switch-2">
                                                Delivery
                                                <label class="switch-inner1">
                                                    <input type="checkbox" id="togBtn22"  v-model="delivery">
                                                    <div :class="['slider ', delivery === true ? 'round' : 'round disabled']">   
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-area-bottom">
                                    <div class="form-group">
                                        <label for="exampleFormControlTextarea1">Item Description<span class="star">*</span></label>
                                        <textarea maxlength="100" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="..." v-model="item_description" ></textarea>
                                        <div id="the-count">
                                            <span id="current" class="limiter">{{charactersLeft}}</span>
                                        </div>
                                        <span v-if="item_description_error" class="error_alert">{{item_description_error_message}}</span>
                                    </div>
                                </div>

                                <div class="image-video-main">
                                    <div class="image-video-heading">
                                        <p>Product Image</p>
                                    </div>
                                    <div class="image-video-inner">
                                        <div class="file-upload">
                                            <div class="image-upload-wrap" v-show="select_img_div">
                                                <div class="drag-text">
                                                     <img  :src="require('@/assets/images/Vector.png')">
                                                </div>
                                            </div>
                                   
                                            <div class="file-upload-content" v-show="preview_img_div">
                                                <div v-for="(image,index) in images" :key="index">
                                                    <img class="file-upload-image" :src="image" >
                                                </div>
                                                <video v-show="video_selected" class="file-upload-image"  id="video" width="300" height="300" controls></video>

                                                <div class="image-title-wrap">
                                                    <button type="button" @click="removeUpload()" class="remove-image">Remove <span class="image-title">Uploaded File</span></button>
                                                </div>
                                            </div>
                                        </div>
                                        <p>Drag & Drop to upload or 
                                            <span>
                                                <button class="browse-button"> 
                                                    <input type="file" class="file-upload-input" accept="image/*" @change="AddImageVideo($event)" name="image" ref="myFileInput" >Browse
                                                </button>
                                            </span>
                                            <span v-if="file_error" class="error_alert">{{file_error_message}}</span>

                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="limit-availability">
                        <div class="container-fluid">
                            <div class="add-details-heading">
                                <p>LIMIT AND AVAILABILITY</p>
                            </div>
                        </div>
                        <div class="border-bottom">
                        </div>
                        <div class="limit-inner">
                            <div class="daily-limit">
                                <div class="container-fluid">
                                    <div class="switch-2">
                                        Daily Limit
                                        <label class="switch-inner1">
                                            <input type="checkbox" id="togBtn23" v-model="daily_limit">
                                            <div   :class="['slider ', daily_limit === true ? 'round' : 'round disabled']">
                                            </div>
                                        </label>
                                    </div>
                                    <br>
                                    <div class="form-group">
                                        <input type="number" class="form-control" id="formGroupExampleInput13" placeholder=".." required v-model="daily_limit_input" max="999" :readonly="daily_limit == false">
                                        <small id="emailHelp" class="form-text text-muted">The limit of menu item for a day. Please ignore it if is unlimited.</small>
                                        <span v-if="daily_limit_error" class="error_alert">{{daily_limit_error_message}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="stock-limit">
                                <div class="container-fluid">
                                    <div class="switch-2">
                                        Stock Limit
                                        <label class="switch-inner1">
                                            <input type="checkbox" id="togBtn24" v-model="stock_limit" >
                                            <div :class="['slider ', stock_limit === true ? 'round' : 'round disabled']">
                                            </div>
                                        </label>
                                    </div>
                                    <br>
                                    <div class="form-group">
                                        <input type="number" class="form-control" id="formGroupExampleInput4" placeholder=".." required v-model="stock_limit_input" max="999" :readonly="stock_limit == false">
                                        <small id="emailHelp" class="form-text text-muted">The available quantity of a menu item. Please ignore it if is unlimited.</small>
                                        <span v-if="stock_limit_error" class="error_alert">{{stock_limit_error_message}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="load time">
                                <div class="stock-limit">
                                    <div class="container-fluid">
                                        <p>Lead time (mins)</p>
                                        <br>
                                        <div class="form-group">
                                            <input type="number" class="form-control" id="formGroupExampleInput16" placeholder=".." required v-model="lead_time" max="999">
                                            <small id="emailHelp" class="form-text text-muted">The cooking time of a menu item. Please ignore it if it doesn’t take time. </small>
                                            <span v-if="lead_time_error" class="error_alert">{{lead_time_error_message}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="limit-buttons">
                    <div class="container-fluid">
                        <router-link  to="/brands-default" replace><button   class="button1">CANCEL</button></router-link>
                        <button @click="update_details()"  class="button2">SAVE</button>
                        <button type="button" class="btn btn-primary button3" data-toggle="modal" data-target="#exampleModal18">
                        <i class="fa fa-trash" aria-hidden="true"></i> DELETE
                        </button>
                    </div>
                </div>

                <!-- modal -->
                <div class="modal fade" id="exampleModal18" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title  mx-auto" id="exampleModalLabel18">Delete Item</h5>
                            </div>
                            <div class="modal-body">
                                <p>Are you sure you want to delete "{{item_name}}" from the menu? </p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" >Cancel</button>
                                <button type="button" data-dismiss="modal" class="btn btn-primary" @click="delete_menu_item(item_id)"> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            
           
        </div>
    </div>
</template>
<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'
    import Header from '../layouts/Header.vue';

    export default {
        components: { Header},
        name: 'EditMenuItem',
        data () {
            return {
                loading:false,
                showHeader: true,
                errors:[],
                brand_name: this.$store.state.brand_name,
                categories:[
                ],
                available:true,
                active:true,
                item_id:'',
                item_name:"",
                item_name_error:false,
                item_name_error_message:'',

                menu_category:'',
                menu_category_error:false,
                menu_category_error_message:'',

                signature_dash:true,
                discount:true,
                promotion:true,

                price:'',
                price_error:false,
                price_error_message:'',

                dine_in:true,
                take_away:true,
                delivery:true,

                item_description:'',
                item_description_error:false,
                item_description_error_message:'',

                daily_limit:true,
                daily_limit_error:false,
                daily_limit_error_message:'',
                daily_limit_input:'',

                stock_limit:true,
                stock_limit_error:false,
                stock_limit_error_message:'',
                stock_limit_input:'',

                lead_time:'',
                lead_time_error:false,
                lead_time_error_message:'',

                menu_category_title:'',
                menu_category_title_error:false,
                menu_category_title_error_message:'',

                file:'',
                images:[],
                select_img_div:true,
                preview_img_div:false,

                video_selected:false,
                file_error:false,
                file_error_message:'',
                order_type:[]
            }
        },
        methods: {
            async delete_menu_item(id){
                this.loading= true;
                try{
                    const headers = { 
                        "Content-Type": "application/json",
                        "Authorization":'Bearer '+this.$store.state.access_token
                    };
                    var api_url = `${baseUrl}/api/menu-items/`+id+`/`
                    const response =  await axios.delete(api_url,{headers})
                    console.log('response',response)
                    this.loading= false;
                    await this.$toasted.success("Menu Item Deleted Successfully");
                    this.$router.push('/brands-default')
                }
                catch (e) {
                    this.loading = false;
                    console.log('error is',e)
                    var i;
                    for (i = 0; i < e.response.data.errors.length; ++i) {
                        this.$toasted.error(e.response.data.errors[i].message)
                    }
                    if(e.response.status== 450){ // logout the user
                        await this.$store.dispatch("logout");
                        await this.$router.push('/')
                    }else if (e.response.status== 401){
                        await this.$RefreshToken()
                    } 
                }
                    


            },

            async update_details(){
                this.errors=[]
                if (this.validate()) {                    
                    this.loading= true;
                    try{
                        const headers = { 
                            "Content-Type": "application/json",
                            "Authorization":'Bearer '+this.$store.state.access_token
                        };
                        if(this.dine_in==true){
                            this.order_type.push('dine_in')
                        }
                        if(this.take_away==true){
                            this.order_type.push('take_away')
                        }
                        if(this.delivery==true){
                            this.order_type.push('delivery')
                        }
                        var brand_id = this.$store.state.brand
                        let formData = new FormData();
                        formData.append('brand', brand_id);
                        if(this.file){
                        formData.append('image', this.file);
                        }
                        formData.append('name', this.item_name);
                        formData.append('category', this.menu_category.id);
                        formData.append('signature_dash', this.signature_dash);
                        formData.append('no_discount', !this.discount);
                        formData.append('promotion', this.promotion);
                        formData.append('price', this.price);
                        // formData.append('dine_in', this.dine_in);
                        // formData.append('take_away', this.take_away);
                        // formData.append('delivery', this.delivery);
                        formData.append('description', this.item_description);
                        formData.append('daily_limit', this.daily_limit_input);
                        formData.append('stock_limit', this.stock_limit_input);
                        formData.append('lead_time', this.lead_time);
                        formData.append('is_active', this.active);
                        formData.append('sold_out', !this.available);
                        for (let i = 0; i < this.order_type.length; i++) {
                            formData.append('order_type', this.order_type[i]);
                        }

                        var api_url = `${baseUrl}/api/menu-items/`+this.item_id+`/`
                        const response =  await axios.patch(api_url,formData,{headers})
                        console.log('response',response)
                        this.loading= false;
                        await this.$toasted.success("Menu Item Edited Successfully");
                        this.$router.push('/brands-default')
                    }
                    catch (e) {
                        this.loading = false;
                        console.log('error is',e)
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                        if(e.response.status== 450){ // logout the user
                            await this.$store.dispatch("logout");
                            await this.$router.push('/')
                        }else if (e.response.status== 401){
                            await this.$RefreshToken()
                        } 
                    }
                    

                }

            },

            validate(){
                this.item_name_error=false
                this.menu_category_error=false
                this.price_error=false
                this.item_description_error=false
                this.daily_limit_error=false
                this.stock_limit_error=false
                this.lead_time_error=false
                this.file_error=false
                if ((!this.item_name) || (this.item_name.length<=0)) {
                    this.item_name_error=true
                    this.item_name_error_message="Please provide Item Name!"
                    this.errors.push("Please provide Item Name!")
                    this.$toasted.error('Please provide Item Name!');
                }


                if ((!this.menu_category) || (this.menu_category.length<=0) ) {
                    this.menu_category_error=true
                    this.menu_category_error_message="Please select Menu Category!"
                    this.errors.push("Please select Menu Category!")
                    this.$toasted.error('Please provide Menu Category!');
                }

                if ((!this.price) || (this.price.length<=0) ) {
                    this.price_error=true
                    this.price_error_message="Please provide Price!"
                    this.errors.push("Please provide Price!")
                    this.$toasted.error('Please provide Price!');
                }

                if ((!this.item_description) || (this.item_description.length<=0) ) {
                    this.item_description_error=true
                    this.item_description_error_message="Please provide Item Description!"
                    this.errors.push("Please provide Item Description!")
                    this.$toasted.error('Please provide Item Description!');
                }

                if (!this.errors.length) {
                    return true;
                }
                 
                 
            },

            removeUpload(){
                this.file = '';
                this.images=[]
                this.select_img_div=true,
                this.preview_img_div=false
                this.$refs.myFileInput.value = '';
                this.video_selected=false
            },

            AddImageVideo(e) {
                this.file_error=false
                var cover_files = e.target.files || e.dataTransfer.files;
                if (!cover_files.length)
                    return;
                // checking type of file
                if(cover_files[0]['type'].split('/')[0] != 'image' && cover_files[0]['type'].split('/')[0] != 'video'  ){
                    this.file_error=true
                    this.file_error_message="please upload valid file type: image/video"
                    this.$toasted.error("please upload valid file type: image/video")
                    return;
                }
                // checking size of file
                var size = parseFloat(cover_files[0].size / 1024).toFixed(2);
                if(cover_files[0]['type'].split('/')[0] === 'image'){
                    if(size>500){
                        this.$toasted.error('Image size should not be more than 500KB')
                        return;
                    }
                }else{
                    if(size>15000){
                        this.$toasted.error('Video size should not be more than 15mb')
                        return;
                    }
                }
                this.file = cover_files[0];
                this.select_img_div=false,
                this.preview_img_div=true
                this.images = [];
                this.video_selected=false

                if(cover_files[0]['type'].split('/')[0] === 'image'){
                    let fileList = Array.prototype.slice.call(e.target.files);
                    fileList.forEach(f => {
                        if(!f.type.match("image.*")) {
                            return;
                        }
                        let reader = new FileReader();
                        let that = this;
                        reader.onload = function (e) {
                            that.images.push(e.target.result);
                        }
                        reader.readAsDataURL(f); 
                    });
                }
                else if(cover_files[0]['type'].split('/')[0] === 'video'){
                    this.video_selected=true
                    const video = document.getElementById('video');
                    const videoSource = document.createElement('source');
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        videoSource.setAttribute('src', e.target.result);
                        video.appendChild(videoSource);
                        video.load();
                        video.play();
                    };
                    reader.onprogress = function (e) {
                        console.log('progress: ', Math.round((e.loaded * 100) / e.total));
                    };
                    reader.readAsDataURL(cover_files[0]);
                }
            }
        },
        mounted(){
    
        },
        async created() {
            this.item_id= this.$route.params.id
            try{
                this.loading= true;
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                const api_url = `${baseUrl}/api/menu-items/`+this.$route.params.id+`/`
                const response =  await axios.get(api_url,{headers})
                this.loading= false;
                this.item_name=response.data.name;
                this.available=!response.data.sold_out;
                this.active=response.data.active;
                this.price=response.data.price;
                this.signature_dash=response.data.signature_dish;
                this.discount=!response.data.no_discount;
                this.promotion=response.data.promotion;
                this.item_description=response.data.description
                if (response.data.image){
                    this.images.push(response.data.image)
                    this.select_img_div=false,
                    this.preview_img_div=true
                }
                if (response.data.daily_limit){
                    this.daily_limit=true
                    this.daily_limit_input=response.data.daily_limit
                }
                else{
                    this.daily_limit=false
                }
                if (response.data.stock_limit ){
                    this.stock_limit=true
                    this.stock_limit_input=response.data.stock_limit
                }
                else{
                    this.stock_limit=false
                }
                if (response.data.lead_time){
                    this.lead_time=response.data.lead_time
                }


                if(response.data.order_type.includes("dine_in")){
                    this.dine_in=true
                }
                else{
                    this.dine_in=false
                }
                if(response.data.order_type.includes("delivery")){
                    this.delivery=true
                }
                else{
                    this.delivery=false
                }
                if(response.data.order_type.includes("take_away")){
                    this.take_away=true
                }
                else{
                    this.take_away=false
                }
                this.menu_category= response.data.category;
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                var i;
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }
        
        
            try{
                const headers = { 
                    "Content-Type": "application/json",
                    "Authorization":'Bearer '+this.$store.state.access_token
                };
                var brand_id = this.$store.state.brand
                const my_params =  {  "brand": brand_id};
                var api_url = `${baseUrl}/api/categories/`
                const response =  await axios.get(api_url,{params: my_params,headers:headers})
                // const response =  await axios.get(api_url,{headers})
                if(response.data.results){
                    for (i = 0; i < response.data.results.length; ++i) {
                        await this.categories.push(response.data.results[i])
                    }
                }
            }
            catch (e) {
                this.loading = false;
                console.log('error is',e)
                for (i = 0; i < e.response.data.errors.length; ++i) {
                    this.$toasted.error(e.response.data.errors[i].message)
                }
                if(e.response.status== 450){ // logout the user
                    await this.$store.dispatch("logout");
                    await this.$router.push('/')
                }else if (e.response.status== 401){
                    await this.$RefreshToken()
                } 
            }
        

        },
        computed: {
            charactersLeft() {
            var char = this.item_description.length,
            limit = 100;
            return (char) + " / " + limit ;
            }
        }
    }
    
</script>
<style>
</style>